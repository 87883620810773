































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mapActions, mapGetters, mapState } from "vuex";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import PageLayout from "@/components/layouts/PageLayout.vue";
import FormRequest from "@/components/FormRequest.vue";
import Button from "@/components/UI/Button.vue";
import ArrowLeft from "@/components/UI/icon/ArrowLeft.vue";
import Pencil from "@/components/UI/icon/Pencil.vue";
import Comment from "@/components/UI/icon/Comment.vue";
import IconBase from "@/components/UI/IconBase.vue";
import Select from "@/components/UI/form/Select.vue";
import Loader from "@/components/UI/Loader.vue";
import Plus from "@/components/UI/icon/Plus.vue";

import {InitialApplicationPaymentStatuses} from "@/constants/InitialApplicationPaymentStatuses";
import { IProduct } from "@/store/modules/products/products.interfaces";
import { PRODUCT_BINDS } from "@/store/modules/products/products.constants";

import {crypto} from "@/store/modules/application";
import ROLES from "@/config/roles";

@Component({
  computed: {
    ...mapState("application", [
      "isEdit",
      "activeTab",
      "isEditUpload",
      "certificate",
      "comment",
      "statusId",
      "paymentStatusId",
      "isCertificateRequested",
      "isKKMReceiptLaunchable",
      "archived",
      "scansFromPreApp",
      "managerComment",
      "errorMessage",
      "isUploadFormLoaded",
      "currentCertThumbprint",
      "newCertRequest",
      "filialClientCertRequest",
      "newCertRevoke",
      "orderId",
      "isPdnUsingProhibited",
      "filialClientFlags",
    ]),
    ...mapState("applicationListState", ["isArchive"]),
    ...mapState("productsState", ["products", "productsSelected"]),
    ...mapGetters("application", [
      "getApplicationEmail",
      "getIsSystemProcessing",
      "getApplicationStatuses",
      "getTypeEntreValue",
      "getIsFormValid",
      "showLicenseRequestButton",
      "enableLicenseRequestButton",
      "getSkziStatus",
    ]),
    ...mapGetters("productsState",["getSelectedNames", "getProductsSelected"]),
    ...mapGetters("authorizationState", ["isManagerPartner", "getRole"])
  },
  methods: {
    ...mapActions("application", [
      "clearFromControlsHandler",
      "fetchApplication",
      "edit",
      "sendToArchive",
      "restoreFromArchive",
      "launchKKMReceipt",
      "clearManagerComment",
      "recieveStatusFromInitialApplicationHub",
      "addDownloadFilesFromSignalR",
      "setCurrentCertThumbprint",
      "saveAndSignDocuments",
      "signCertRequest",
      "uploadSignedCertRequest",
      "signCertRevoke",
      "uploadSignedCertRevoke",
      "handlerHeadSign",
      "clearErrorMessage",
      "cryptoLoad",
      "getCertificates",
      "submitHandler",
      "cloneApplication",
      "defineAddressFields",
      "readyForUpd",
      "setActiveTab",
      "checkValidityAndScroll",
    ]),
    ...mapActions("productsState", ["resetProducts"]),
    ...mapActions("comments", ["getLastComment", "clearCommentsState"]),
  },
  components: {
    FormRequest,
    Button,
    ArrowLeft,
    Pencil,
    IconBase,
    Comment,
    PageLayout,
    Header,
    Footer,
    Select,
    Loader,
    Plus
  }
})
export default class Application extends Vue {
  /* data */
  public сertificates: [] = [];
  public modalErrorClose = false;
  public showTooltip: boolean = false;
  public isBrowserPluginEnabled: boolean = crypto.browserPluginEnabled;
  public notPaidStatusId : InitialApplicationPaymentStatuses = InitialApplicationPaymentStatuses.NotPaid;

  /* computed */
  public orderId!: number;
  public statusId!: number;
  public newCertRevoke!: any;
  public newCertRequest!: any;
  public filialClientCertRequest!: any;
  public $connectionHub!: any;
  private products!: IProduct[];
  public scansFromPreApp!: any[];
  public getSelectedNames!: string;
  public getTypeEntreValue!: string;
  public getApplicationEmail!: string;
  public isUploadFormLoaded!: boolean;
  public getApplicationStatuses!: any;
  private productsSelected!: number[];
  public getProductsSelected!: Array<IProduct>;
  public managerComment!: null | string;
  public getIsSystemProcessing!: boolean;
  public getSkziStatus!: string;
  public isCertificateRequested!: boolean;
  public filialClientFlags!: number | null;
  public isArchive!: boolean;
  public getRole!: ROLES;
  
  public get isHeadManagerSignWaiting(): boolean {
    return this.statusId === this.getApplicationStatuses.HeadManagerSign.id;
  }

  public get possibleToArchive() {
    return (statusId: number) => {
      return statusId == this.getApplicationStatuses.DocumentsSending.id || 
        statusId == this.getApplicationStatuses.CertificateRequest.id ||
        statusId == this.getApplicationStatuses.HeadManagerSign.id;
    }
  }

  public get isEditableByStatus() {
    return (statusId: number) => {
      return statusId !== this.getApplicationStatuses.CertificateReady.id 
        && statusId !== this.getApplicationStatuses.Rejection.id
        && statusId !== this.getApplicationStatuses.EDORegistration.id
        && statusId !== this.getApplicationStatuses.WaitingForOriginals.id
        && statusId !== this.getApplicationStatuses.OriginalsIncorrect.id
        && statusId !== this.getApplicationStatuses.Closed.id;
    }
  }

  public get isEditableByRole() {
    const role = this.getRole;
    return [ROLES.Admin, ROLES.AdminSkzi, ROLES.Manager, ROLES.ChiefManager].includes(role);
  }

  public getResultPrice(typeEntre: string) {
    return this.productsSelected.reduce((sum, id) => {
      const product: IProduct | undefined = this.products.find(p => p.id === id);
      if (product && product.price) {
        return sum + Number(product.price[typeEntre]);
      }
      return sum;
    }, 0);
  }

  public get signTargetName() {
    if (!!this.newCertRequest) return 'файл запроса';
    if (
      !this.isCertificateRequested &&
      ((Number(this.filialClientFlags) & 0x0002) == 0x0002) &&
      this.statusId !== this.getApplicationStatuses.Rejection.id &&
      !this.isArchive
    ) return this.filialClientCertRequest ? 'файл запроса' : 'пакет документов'; // ищи такое же условие в FormRequest
    if (!!this.newCertRevoke) return 'файл отзыва';
    return 'пакет документов';
  }

  public get isEnabledCloseConfirmModal() {
    if (!!this.newCertRequest) return false;
    if (
      !this.isCertificateRequested &&
      ((Number(this.filialClientFlags) & 0x0002) == 0x0002) &&
      this.statusId !== this.getApplicationStatuses.Rejection.id &&
      !this.isArchive
    ) return !this.filialClientCertRequest;
    return true;
  }

  /* methods */
  public edit!: () => void;
  public cryptoLoad!: () => void;
  public readyForUpd!: () => void;
  public resetProducts!: () => void;
  public sendToArchive!: () => void;
  public submitHandler!: () => void;
  public getCertificates!: () => [];
  public signCertRevoke!: () => void;
  public signCertRequest!: (uploaded:any) => void;
  public launchKKMReceipt!: () => void;
  public clearErrorMessage!: () => void;
  public restoreFromArchive!: () => void;
  public clearCommentsState!: () => void;
  public clearManagerComment!: () => void;
  public saveAndSignDocuments!: () => void;
  public uploadSignedCertRevoke!: () => void;
  public checkValidityAndScroll!: () => void;
  public fetchApplication!: (id: any) => void;
  public uploadSignedCertRequest!: () => void;
  public clearFromControlsHandler!: () => void;
  public getLastComment!: (text: string) => void;
  public addDownloadFilesFromSignalR!: (data: any) => void;
  public defineAddressFields!: (hasTariffInsurance: boolean) => void;
  public recieveStatusFromInitialApplicationHub!: (data: any) => void;
  // public getCustomerEmail!: () => string;

  public archive() {
    this.$modal.show("dialog", {
      title: "ВНИМАНИЕ",
      text: 'Вы уверены? Заявка будет доступна только в разделе "Архив".',
      buttons: [
        {
          title: "ПРОДОЛЖИТЬ",
          default: true,
          handler: () => {
            this.sendToArchive();
            this.$modal.hide("dialog");
          }
        },
        {
          title: "ОТМЕНА",
          handler: () => {
            this.$modal.hide("dialog");
          }
        }
      ]
    });
  }

  public restore() {
    this.$modal.show("dialog", {
      title: "ВНИМАНИЕ",
      text: 'Вы уверены? Заявка будет доступна разделе "Заявки".',
      buttons: [
        {
          title: "ПРОДОЛЖИТЬ",
          default: true,
          handler: () => {
            this.restoreFromArchive();
            this.$modal.hide("dialog");
          }
        },
        {
          title: "ОТМЕНА",
          handler: () => {
            this.$modal.hide("dialog");
          }
        }
      ]
    });
  }

  private acceptEdit(activeTab: number) {
    if (/(1|2)/.test(activeTab.toString())) {
      this.$modal.show("dialog", {
        title: "Внимание",
        text:
          !this.getIsSystemProcessing ? 
          "Изменение полей заявки приведет к аннулированию текущей версии заявки. Вам потребуется заново приложить пакет документов. Продолжить?" :
          'Заявка находится в стадии обработки системой. После её изменения процесс обработки начнётся заново. Продолжить?',
        buttons: [
          {
            title: "Отмена"
          },
          {
            title: "ПРОДОЛЖИТЬ",
            default: true,
            handler: () => {
              this.edit();

              this.$modal.hide("dialog");
            }
          }
        ]
      });
    }

    if (activeTab === 3) {
      this.$modal.show("dialog", {
        title: "Внимание",
        text:
          "Вам потребуется заново приложить весь пакет документов. Продолжить?",
        buttons: [
          {
            title: "Отмена"
          },
          {
            title: "ПРОДОЛЖИТЬ",
            default: true,
            handler: () => {
              this.edit();
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    }
  }

  public startPay() {
    const email = this.getApplicationEmail;
    const sum = this.getResultPrice(this.getTypeEntreValue);
    const names = this.getSelectedNames;
    this.$modal.show("dialog", {
      title: "ВНИМАНИЕ",
      text: `Сейчас будет пробит чек на сумму: ${sum}₽ на позиции: ${names} и отправлен на email: ${email}. Подтвердите операцию.`,
      buttons: [
        {
          title: "ПРОДОЛЖИТЬ",
          default: true,
          handler: () => {
            this.launchKKMReceipt();
            this.$modal.hide("dialog");
          }
        },
        {
          title: "ОТМЕНА",
          handler: () => {
            this.$modal.hide("dialog");
          }
        }
      ]
    });
  }

  public beforeClose(evt: any) {
    this.modalErrorClose = true;
    this.сertificates = [];

    this.clearErrorMessage();

    if (!this.isUploadFormLoaded) {
      evt.stop();
    }
  }

  public async openSignModal() {
    this.cryptoLoad();
    this.isBrowserPluginEnabled = crypto.browserPluginEnabled;

    if (this.isBrowserPluginEnabled) {
      this.сertificates = await this.getCertificates();
    }

    this.modalErrorClose = false;
    this.$modal.show("confirm");
  }

  public async signClickHandler() {
    if(this.filialClientCertRequest){
      await this.signCertRequest(this.filialClientCertRequest);
      this.uploadSignedCertRequest();
    } else if (this.newCertRequest) {
      await this.signCertRequest(null);
      if (this.orderId) {
        this.uploadSignedCertRequest();
      } else {
        this.submitHandler();
      }
    } else if (this.newCertRevoke) {
      await this.signCertRevoke();
      this.uploadSignedCertRevoke();
    } else {
      this.saveAndSignDocuments();
    }
  }

  @Watch("managerComment")
  watchManagerComment() {
    if (this.managerComment !== null) {
      this.getLastComment(this.managerComment);
    }
  }

  @Watch("newCertRequest")
  newCertRequestWatcher() {
    if (!!this.newCertRequest) {
      this.openSignModal();
    }
  }

  @Watch("newCertRevoke")
  newCertRevokeWatcher() {
    if (!!this.newCertRevoke) {
      this.openSignModal();
    }
  }

  @Watch("getProductsSelected")
  getProductsSelectedWatcher() {
    const hasTariffInsurance = this.getProductsSelected
      .some(({bind}: IProduct) => PRODUCT_BINDS.Insurance.includes(bind));
    this.defineAddressFields(hasTariffInsurance);
  }

  /* life circle methods */
  public created() {
    this.$connectionHub.$on("status-changed", this.recieveStatusFromInitialApplicationHub);
    this.$connectionHub.$on("revoke-application-created", this.addDownloadFilesFromSignalR);
    this.$connectionHub.start();
  }
  
  public async mounted() {
    const { orderId } = this.$route.params;

    if (orderId) {
      this.$connectionHub.requestStatus(orderId+"");

      this.fetchApplication(orderId);
    }
  }

  public beforeDestroy() {
    this.$connectionHub.$off("status-changed", this.recieveStatusFromInitialApplicationHub);
    this.$connectionHub.$off("revoke-application-created", this.addDownloadFilesFromSignalR);
    this.$connectionHub.stop();
  }

  public destroyed() {
    this.clearFromControlsHandler();
    this.resetProducts();
    this.clearManagerComment();
    this.clearCommentsState();
  }
}
